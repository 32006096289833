import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { HomepassLogoFullscreen } from '../components';

const Index: NextPage = () => {
  const gitHash = '__GITHUB_HASH_REPLACE_ME__';

  return (
    <>
      <Head>
        <title>Welcome</title>
        <meta property="og:title" content={gitHash} />
        <meta property="og:type" content="website" />
      </Head>
      <HomepassLogoFullscreen />
    </>
  );
};

export default Index;
